import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
//import { brown } from '@mui/material/colors';
import { customColors, customStyles } from '../UI/Theme_v2';

export default function InvoiceCreator_Step2({ invoiceData = {}, matches3 }) {
    //console.log("my data...",invoiceData)
    
  return (
    <Paper
      style={{ padding: "20px", backgroundColor: customColors.background }}
    >
      <Typography
        variant="h6"
        gutterBottom
        style={{ color: customColors.ludisMain }}
      >
        Invoice Summary
      </Typography>
      <Grid container spacing={3}>
        {Object.entries(invoiceData).map(([key, value]) => {
          if (key !== "projectId" && key !== "purchaseOrder_id" && key !=="supplierId" && key !=="purchaseOrder_ProductId") {
            return (
              <Grid item xs={12} sm={6} key={key}>
                <Typography
                  variant="subtitle1"
                  style={{ color: customColors.ludisMain }}
                  sx={{fontSize: matches3 ? "0.6rem" : "0.8rem",}}
                >
                  {key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/([A-Z])/g, " $1")}
                  :
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{fontSize: matches3 ? "0.6rem" : "0.8rem",}}
                >
                  {value === 0 ? 0 : value || "N/A"}
                </Typography>
              </Grid>
            );
          }
        })}
      </Grid>
    </Paper>
  );
}

