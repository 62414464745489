import { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { purchasePaymentOutActions } from "../../../store/purchasePayment-slice";
import { supplierActions } from "../../../store/supplier-slice";

import axios from "axios";
import DashboardContainer from "../../UI/DashboardContainer";
import { variables } from "../../../Variables";
import theme from "../../UI/Theme";
//import BarChart from "../BarChart";

import Title from "../../Title";
import {
  MyTable,
  MyTableRow,
  MyTable2,
} from "../../UI/styledComponents/StyledComponents";
import AddPaymentSteps from "../../paymentsComponent/AddPaymentSteps";
import AddPurchasePaymentStep_1 from "../../purchaseComponents/AddPurchasePaymentStep_1";
import AddPurchasePaymentStep_2 from "../../purchaseComponents/AddPurchasePaymentStep_2";
import AddPurchasePaymentStep_3 from "../../purchaseComponents/AddPurchasePaymentStep_3";

import InvoiceCreator from "../../purchaseComponents/InvoiceCreator"


//import ChangePayDateSteps from "../../paymentsComponent/ChangePayDateSteps";
//import ChangePayDateStep_1 from "../../paymentsComponent/ChangePayDateStep_1";
//import ChangePayDateStep_2 from "../../paymentsComponent/ChangePayDateStep_2";
import ChangePayDateStep_3 from "../../paymentsComponent/ChangePayDateStep_3";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import Draggable from "react-draggable";

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
//import TableCell from "@mui/material/TableCell";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Collapse from "@mui/material/Collapse";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.common.ludisMain,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const DisplayExtraInfo = (props) => {
  const { open1, item } = props;

  return (
    <TableRow>
      {/* Extra information collapsable  */}
      {/* <TableRow key={item.CLIENTPAYMENT_ID}> */}
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse
          in={open1[item.PURCHASEORDER_ID]}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Purchase Inv. Details....
            </Typography>

            <MyTable2 size="small" aria-label="payments">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#fff" }}>
                  <TableCell>Invoice</TableCell>
                  <TableCell>...</TableCell>
                  <TableCell>Purchase Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={item.PURCHASEORDER_ID}>
                  <TableCell component="th" scope="row">
                    {item.PURCHASEINVOICE_NUMBER}
                    {/* {new Date(item.PAYMENTDATE).toLocaleString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} */}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    
                    {/* {new Date(item.DATEONSTATEMENT).toLocaleString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} */}
                  </TableCell>
                  <TableCell>{item.PURCHASEINVOICE_DESCRIPTION}</TableCell>
                </TableRow>
              </TableBody>
            </MyTable2>
          </Box>
        </Collapse>
      </TableCell>
      {/* End Extra information collapsable */}
    </TableRow>
  );
};

export default function PurchaseOrderReportData(props) {
  const dispatch = useDispatch();
  const currentPurchaseInvoceSelected = useSelector(
    (state) => state.purchasePaymentOut.purchasePaymentOutInfo
  );

  const currentSupplierSelected = useSelector(
    (state) => state.supplier.supplierInfo
  );



  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));

  const [showPurchaseInvoicesList, setShowPurchaseInvoicesList] = useState(true);
  const [totalPayments, setTotalPayments] = useState(null);

  //variables for the popover (hoover)
  const [paymentRef, setPaymentRef] = useState("");

  const [showAddPurchasePayment, setShowAddPurchasePayment] = useState(false);

  //isLoading1_allPayOnOrder is to pass to the parent component Income.js info about loading to share with sibling componente paymentinfoOrderSummary.js
  const {
    purchasePoBySupplierByDate,
    purchaseInvPaymentsBySupplierByDate,
    purchasePaymentsTypeList,
    allPurchaseInvoicesWithPaymentsFull,
    isLoading1,
    showPayDate,
    matches,
    isUpdatedPurchaseInvoice,
    socket,
    matches3,
  } = props;


  //console.log props....
  if(allPurchaseInvoicesWithPaymentsFull.length >0){

    
    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull",allPurchaseInvoicesWithPaymentsFull)


    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull",allPurchaseInvoicesWithPaymentsFull[0].pOrder.PURCHASEINVOICE_PAYDAY)
  }else{
    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull = EMPTY ARRAY ")
  }
  //console.log("FROM THE PROPS....purchasePoBySupplierByDate",purchasePoBySupplierByDate)


  let repDate = "";
  let repDate1 = "";
  let repDate2 = "";


  let variableDate1 = "";
  let variableDate2 = "";


  const showPaymentsHandler = (event) => {
    event.preventDefault();
    setShowPurchaseInvoicesList((prev) => !showPurchaseInvoicesList);
  };

  const resetPurchasePaymentInfo = () => {
    dispatch(
      purchasePaymentOutActions.resetPurchasePaymentOut({
        purchasePaymentDate: new Date().toISOString(),
      })
    );

  };

  //Update Paymnet In details into the slice---------------------
  const updatePurchasePaymentInfo = (purchasePaymentInfo) => {
    //console.log("Purchase payment info insde the update function....", purchasePaymentInfo);
    //console.log("Purchase payment info insde the update function....", purchasePaymentInfo.pOrder.PURCHASEINVOICE_ID);


    //if (purchasePaymentInfo) {
      if(Object.keys(purchasePaymentInfo).length !== 0) {
      //Add the Selected Order to the Payments In information
      dispatch(
        purchasePaymentOutActions.updatePurchasePaymentOut({
          //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(), //---here so far....
          selectedPurchaseInvoice: [
            {
              // "confirmationId":paymentInfo.CONFIRMATION_ID
              PURCHASEINVOICE_ID: purchasePaymentInfo.pOrder.PURCHASEINVOICE_ID,
              PURCHASEINVOICE_NUMBER: purchasePaymentInfo.pOrder.PURCHASEINVOICE_NUMBER,
              SUPPLIER_ID: purchasePaymentInfo.SUPPLIER_ID,
            },
          ],

        })
      );
    } else {
      resetPurchasePaymentInfo();
      //console.log("error, date not usable only 2 options available");
      //return;
    }
  };

  //Show the modal with form to submit Purchase Invoice payment-----------
  const showPurchasePaymentModalHandler = (event, action, item) => {
    resetPurchasePaymentInfo();
    if (event) {
      event.preventDefault();
      //console.log("Purchase Invoice Id here....", item.pOrder.PURCHASEINVOICE_ID);
    }
    if (action === 1) {
      setShowAddPurchasePayment((prev) => !showAddPurchasePayment);
      updatePurchasePaymentInfo(item || {});
    } else if (action === 2) {
      //setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      //setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      //setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };



  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    
  ];

  //POP OVER CODE --------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);

  const [open1, setOpen1] = useState(false);

  const handlePopoverOpen = (event, reference) => {
    //console.log(reference)
    setAnchorEl(event.currentTarget);
    setPaymentRef(reference);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPaymentRef("");
  };

  const handleCollapse = (event, item) => {
    event.preventDefault();
    //console.log("el eventoooooo....", event.currentTarget);
    //console.log("el eventoooooote....", item.PURCHASEINVOICE_ID);

    setOpen1((prev) => ({
      ...prev,
      [item.PURCHASEORDER_ID]: !prev[item.PURCHASEORDER_ID],
    }));

    //test to be deleted------
    //paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };


  const open = Boolean(anchorEl);

  //Steps for Add a Signed Payment Plan to the order-------------------------------------
  const steps_AddPurchasePayment = [

    {
      label: "Purchase Payment Details",
      info: (
        <AddPurchasePaymentStep_1
          //usersTitleList={usersTitleList}
          purchasePaymentsTypeList={purchasePaymentsTypeList}
        />
      ),
      icon: <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <PaymentOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <AddPurchasePaymentStep_2 />,
      //info: "Payment Summary component...",
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: ( 
        <AddPurchasePaymentStep_3
          socket={socket}
          showPurchasePaymentModalHandler={showPurchasePaymentModalHandler}
          // isUpdatedPurchaseInvoice = {isUpdatedPurchaseInvoice}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },

  ];

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)

    if (purchasePoBySupplierByDate?.length > 0) {
      setTotalPayments(
        purchasePoBySupplierByDate[purchasePoBySupplierByDate.length - 1]
          .cumulative_sum
      );
    } else {
      setTotalPayments(0);
    }


  }, [purchasePoBySupplierByDate]);

  if (showPayDate) {
    variableDate1 = "PayDate";
    variableDate2 = "PO.Date";

  } else {
    variableDate1 = "PO.Date";
    variableDate2 = "PayDate";

  }

  

  return (
    //paymentsByDateList.length > 0 ?
    <Fragment>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          alignItems: "flex-start",
          
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showPaymentsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />

          {showPurchaseInvoicesList ? "Hide Invoices" : "Show Invoices"}
        </Button>
      </Box>

      {showPurchaseInvoicesList ? (
        !isLoading1 ? (
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
              
            }}
          >
            <Title>Purchase Orders ...</Title>

            <Divider sx={{ width: "60vw" }} />

            {/* {purchaseInvBySupplierByDate?.length > 0 ? ( */}
            {/* {allPurchaseInvoicesWithPaymentsFull?.length > 0 ? ( */}
            {purchasePoBySupplierByDate?.length > 0 ? (
                
              <TableContainer
                component={Paper}
                sx={{ width: matches2 ? "80vw" : "60vw", marginTop: 2 }}
              >
                
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">...</StyledTableCell>
                      <StyledTableCell align="left">{variableDate1}</StyledTableCell>
                      <StyledTableCell align="left">PO.No.</StyledTableCell>
                      <StyledTableCell align="center">{variableDate2}</StyledTableCell>
                      {/* <StyledTableCell align="right">N.Days(PayDay)</StyledTableCell> */}
                      <StyledTableCell align="right">
                        Net &nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="right">Vat</StyledTableCell>
                      <StyledTableCell align="right">Total</StyledTableCell>
                      <StyledTableCell align="center">Inv</StyledTableCell>
                      <StyledTableCell align="right">Status</StyledTableCell>
                      <StyledTableCell align="right">...</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {/* {purchaseInvBySupplierByDate.map((item) => { */}
                    {/* {allPurchaseInvoicesWithPaymentsFull?.map((item) => { */}
                    {purchasePoBySupplierByDate?.map((item) => {

                      //console.log("this is the item inside the map.....",item)

                      showPayDate
                        ? (repDate = item.PURCHASEORDER_DATE)
                        : (repDate = item.PURCHASEORDER_DATE);
                      showPayDate
                        ? (repDate1 = item.PURCHASEORDER_DATE)
                        : (repDate1 = item.PURCHASEORDER_DATE);
                      return (
                        <Fragment>
                          <MyTableRow key={item.PURCHASEORDER_ID}>
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                              >
                                {open1[item.PURCHASEORDER_ID] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {new Date(repDate).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {item.PURCHASEORDER_REF}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {new Date(
                                  repDate1
                                ).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              {/* £{" "} */}
                              {parseFloat(
                                item.PURCHASEORDER_NETPRICE
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>

                            <TableCell>
                              {/* £{" "} */}
                              {parseFloat(
                                item.PURCHASEORDER_VAT
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>

                            <TableCell>
                              {/* £{" "} */}
                              {parseFloat(
                                item.PURCHASEORDER_VAT +
                                  item.PURCHASEORDER_NETPRICE
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>

                            {/* <TableCell> */}
                              {/* £{" "} */}
                              {/* {parseFloat(
                                item.totalPaid
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })} */}
                            {/* </TableCell> */}

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {item.PURCHASEINVOICE_NUMBER}
                              </Typography>
                            </TableCell>

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {/* {(parseFloat(item.pOrder.PURCHASEINVOICE_VAT) + parseFloat(item.pOrder.PURCHASEINVOICE_NETPRICE) - parseFloat(item.totalPaid)) === parseFloat(0) && */}
                                {(item.PURCHASEINVOICE_NUMBER) &&

                                <CheckCircleOutlineIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.okGreen,
                                  marginLeft: 1,
                                }}
                              />}

                               {/* {Math.round(item.pOrder.PURCHASEINVOICE_VAT + item.pOrder.PURCHASEINVOICE_NETPRICE - item.totalPaid) < 0 &&  */}
                               {/* {!item.PURCHASEINVOICE_NUMBER && 
                                <ReportGmailerrorredIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.ludisRed2,
                                  marginLeft: 1,
                                }}
                              />} */}

                               {/* {Math.round(item.PURCHASEORDER_VAT + item.PURCHASEORDER_NETPRICE - item.totalPaid) > 0 &&  parseFloat(item.totalPaid) > 0 && */}
                               {!item.PURCHASEINVOICE_NUMBER &&

                                <RunningWithErrorsIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.arcOrange,
                                  marginLeft: 1,
                                }}
                              />}

                              </Typography>
                            </TableCell>


                            <StyledTableCell align="right">
                                <InvoiceCreator 
                                PoSelected={item} 
                                isUpdatedPurchaseInvoice = {isUpdatedPurchaseInvoice} 
                                socket={socket}
                                 matches3={matches3}
                                 />
                              {/* <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  showPurchasePaymentModalHandler(event, 1, item);
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 1,
                                  }}
                                />
                              </Button> */}
                            </StyledTableCell>
                          </MyTableRow>

                          {open1[item.PURCHASEORDER_ID] && (
                            <DisplayExtraInfo open1={open1} item={item} />
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      {/* Modal for case: Show payment plan signed Details ---------------------- */}
      <Dialog
        open={showAddPurchasePayment}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showPurchasePaymentModalHandler(event, 1)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>Purchase Payment Details</DialogTitle>
        <DialogContent>
          {currentPurchaseInvoceSelected.selectedPurchaseInvoice.length > 0 && (
            <>
              <DialogContentText sx={{ margin: 2 }}>
              Purchase Invoice...{currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER}
              
              </DialogContentText>
              <DialogContentText sx={{ margin: 2 }}>
              
              Supplier: {currentSupplierSelected.selectedSupplier.SUPPLIER_NAME}
              </DialogContentText>
            </>
            )}
          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
               {/* Add a new Purchase Payment ... */}
          {/* <ChangePayDateSteps
            steps={steps_AddPurchasePayment}
            showModalHandler={showModalHandler}
          /> */}

          <AddPaymentSteps steps={steps_AddPurchasePayment} />

        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
}
