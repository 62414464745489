import axios from "axios";
import { useEffect, useState, Fragment, useRef } from "react";

import { useDispatch } from "react-redux";
import { purchasePaymentOutActions } from "../../../store/purchasePayment-slice";
import { supplierActions } from "../../../store/supplier-slice";

import html2canvas from "html2canvas";

//------MUI IMPORTS------------------------------------------
import Box from "@mui/material/Box";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import ReceiptIcon from "@mui/icons-material/Receipt";
import ComputerIcon from "@mui/icons-material/Computer";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GradingIcon from "@mui/icons-material/Grading";

import theme from "../../UI/Theme";

import DashboardContainerReport from "../../UI/DashboardContainerReport";
//import PaymentsReportByDate from "../PaymentsReportByDate";
import PurchaseInvoicesReportData from "./PurchaseInvoicesReportData";
import { variables } from "../../../Variables";
import { MyTextField } from "../../UI/styledComponents/StyledComponents";
import PaymentsIn from "../../paymentsComponent/PaymentsIn";
import Deposits from "../../Deposits";
import PurchaseReportSummary from "./PurchaseReportSummary";


import Title from "../../Title";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

let reportVariables = {
  //consultantSelected_id: "",
  selectedDatesFrom: "",
  selectedDatesTo: "",
};

export default function PurchaseInvoicesReport(props) {

    const dispatch = useDispatch();

    const AutoCompleteSuppliersList = useRef();
    const [suppliersListFiltered, setSuppliersListFiltered] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const { socket, suppliersList, purchasePaymentsTypeList } = props;


  const [consultantListFiltered, setConsultantListFiltered] = useState([]);

  const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  const [selectedDateTo, setSelectedCDateTo] = useState(null);

  const [purchaseInvBySupplierByDate, setPurchaseInvBySupplierByDate] = useState([]);
  const [purchaseInvPaymentsBySupplierByDate, setPurchaseInvpaymentsBySupplierByDate] = useState([]);
  const [allPurchaseInvoicesWithPaymentsFull, setAllPurchaseInvoicesWithPaymentsFull] = useState([])
  const [ allOutsPurchaseData,  setAllOutsPurchaseData]= useState(null);
  const [totalPurchasePaymentsByDate, setTotalPurchasePaymentsByDate] = useState(null);
  const [totalPurchasesBySupplierByDate, setTotalPurchasesBySupplierByDate] = useState(null);
  
  //const [showPaymentsList, setShowPaymentsList] = useState(false)

  const [notification, setNotification] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [showPayDate, setShowPayDate] = useState(false);
  const [updatedPurchasePayment, setUpdatedPurchasePayment] = useState(null);

  //const [newPurchasePayment, setNewPurchasePayment]=useState(false);


  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("xl"));

  //console.log("here is the suppliers list from invoices...", suppliersList);

  //to be deleted....
  const [locale, setLocale] = useState("de");
  //to be deleted end....

  //Function to manage the state to check if new Payment has been recorded---------------------------------
  const isUpdatedPurchasePayment = (payId)=>{
    setUpdatedPurchasePayment(payId);
  }

  // //to show payments list as per Bank Statements or Recorde Date-----------------------------------
  const showPayDateHandler = (event) => {
    event.preventDefault();
    setShowPayDate((prev) => !showPayDate);
  };

  const resetReportVariables = () => {
    reportVariables = {
      ...reportVariables,
      // consultantSelected_id: "",
      selectedDatesFrom: "",
      selectedDatesTo: "",
    };
  };

  // const isNewPurchasePayment = (payId)=>{  
  //   setNewPurchasePayment(payId);
  // }

  


  //for the supplier list----
  useEffect(() => {
    if (suppliersList?.length > 0) {
      const mySuppliersListFiltered = suppliersList.map((option) => {
        const firstLetter = option.SUPPLIER_NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setSuppliersListFiltered(mySuppliersListFiltered);
    }
  }, [suppliersList]);

 

  //Purchase Invoice list By supplier for the periode of dates ----------------
  const getAllPurchaseInvBySupplierByDate = (selectedDatesFrom, selectedDatesTo, suppId) => {
    const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
    const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();


    setIsLoading1(true);
    axios
      .get(
        variables.API_URL +
          "purchase/purchaseinvbysupplierbydate/" +
          // paymentsUrl +
          selectedDatesFrom_2 +
          "/" +
          selectedDatesTo_2 +
          "/" +
          suppId +
          "/" + 
          showPayDate,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log(res.data);
        //setPaymentsByDateList(res.data.purchaseInvBySupplierByDate);
        setPurchaseInvBySupplierByDate(res.data.purchaseInvBySupplierByDate);


        // if (res.data.paymentsByDate.length === 0) {
        //   setTotalPaymentsByDate(0);
        //   //setTotalPagesByConsultant(0);
        // } else {
        //   const totalPaid = res.data.purchaseInvBySupplierByDate.slice(-1);
        //   setTotalPaymentsByDate(totalPaid[0].cumulative_sum);
        // }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification("Something went wrong, please try again");
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  };



  //Purchase Invoice Payments list By supplier for the periode of dates ----------------
  const getAllPurchaseInvPaymentsBySupplierByDate = (selectedDatesFrom, selectedDatesTo, suppId) => {
    const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
    const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();


    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          "purchase/purchaseinvpaymentsbysupplierbydate/" +
          // paymentsUrl +
          selectedDatesFrom_2 +
          "/" +
          selectedDatesTo_2 +
          "/" +
          suppId ,
          // + "/" + 
          // showPayDate,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log("pruchase payments data by supplier.....",res.data.purchaseInvPaymentsBySupplierByDate);
        //setPaymentsByDateList(res.data.purchaseInvBySupplierByDate);
        setPurchaseInvpaymentsBySupplierByDate(res.data.purchaseInvPaymentsBySupplierByDate);


        // if (res.data.paymentsByDate.length === 0) {
        //   setTotalPaymentsByDate(0);
        //   //setTotalPagesByConsultant(0);
        // } else {
        //   const totalPaid = res.data.purchaseInvBySupplierByDate.slice(-1);
        //   setTotalPaymentsByDate(totalPaid[0].cumulative_sum);
        // }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification("Something went wrong, please try again");
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  };




    //Get all purchase Invoices with payments per purchase Invoice -- the final summary info -------------------------------------------------------------
  
  let allPurchaseInvoicesWithPayments = [];

  const getAllPaidByPurchaseInvoice =  () => {

     //alert("GetAllPaidByPurchaseInvoice... inside Outstanding component Called !!")

     

     const purchaseInvBySupplierByDate_sorted = purchaseInvBySupplierByDate.sort((a, b) => new Date(a.PURCHASEINVOICE_DATE) - new Date(b.PURCHASEINVOICE_DATE))

     //console.log("SORTED.....",purchaseInvBySupplierByDate_sorted)



    let allPurchase = 0; //starting value
    let allPayments = 0; //starting value
    let allWoff = 0; //starting value
    let allOuts = 0; //starting value
    let allOuts_first50 = 0; //starting value
    let allOuts_second50 = 0; //starting value

    let totalBalance = 0; //starting value

    //purchaseInvBySupplierByDate.map((pInv) => {
    purchaseInvBySupplierByDate_sorted?.map((pInv) => {

      //console.log("inthemap pInv....", pInv)
      let totalPaidOnOrder = 0;
      let totalWoffOnOrder = 0;
      let totalOuts = 0;
      let totalOuts_first50 = 0;
      let totalOuts_second50 = 0;

      let paymentsOnOrderList = [];
      let woffOnOrderList = [];





      //finding all payments on one specific order
      const allPaid2 = purchaseInvPaymentsBySupplierByDate?.filter(
        (purchasePayment) => pInv.PURCHASEINVOICE_ID === purchasePayment.PURCHASEINVOICE_ID
      );

      //console.log("after filtered...", allPaid2)

      if (allPaid2.length > 0) {
        totalPaidOnOrder = allPaid2[0].subTotal;
        paymentsOnOrderList = allPaid2;
      }






      //finding all written off on one specific order
      // const allWoff2 = woffByDateByOrderList.filter(
      //   (woff) => order.CONFIRMATION_ID === woff.CONFIRMATION_ID
      // );

      // if (allWoff2.length > 0) {
      //   totalWoffOnOrder = allWoff2[0].subTotal;
      //   woffOnOrderList = allWoff2;
      // }

      allPurchase = parseFloat(allPurchase) + parseFloat(pInv.PURCHASEINVOICE_TOTAL );
      // .toLocaleString("en-US",{minimumFractionDigits: 2,})

      //allPurchase = allPurchase + pInv.TOTALPAID;
      allPayments = parseFloat(allPayments) + parseFloat(totalPaidOnOrder);
      allWoff = parseFloat(allWoff) + parseFloat(totalWoffOnOrder);
      totalBalance = parseFloat(totalBalance) + parseFloat(pInv.PURCHASEINVOICE_TOTAL) - parseFloat(totalPaidOnOrder) - parseFloat(totalWoffOnOrder);

     
      totalOuts = parseFloat(pInv.PURCHASEINVOICE_TOTAL)  - parseFloat(totalPaidOnOrder) - parseFloat(totalWoffOnOrder); // When project is completed Full Amount is due from total
      //totalOuts_first50 = 0;
      // totalOuts_second50 =
      // pInv.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total
      

      allOuts = parseFloat(allOuts) + parseFloat(totalOuts);
      //allOuts_first50 = allOuts_first50 + totalOuts_first50;
      //allOuts_second50 = allOuts_second50 + totalOuts_second50;

      //alert("order..."+order.CONFIRMATION_REFERENCE+"allOut so far...." + allOuts+ "total out order...." + totalOuts)

      allPurchaseInvoicesWithPayments.push({
        pOrder: pInv,
        totalPaid: totalPaidOnOrder,
        totalWoff: totalWoffOnOrder,
        paymentsOnOrderList: paymentsOnOrderList,
        woffOnOrderList: woffOnOrderList,
        totalOuts: totalOuts,
        totalBalance: totalBalance,
        //totalBalance: parseFloat(pInv.TOTALPAID) - parseFloat(totalPaidOnOrder) - parseFloat(totalWoffOnOrder),
        //totalBalance: parseFloat(pInv.TOTALPAID) ,

      });
    });

    //console.log("PURCHASE !!! All Outstanding...", allOuts);
    // console.log("PURCHASE !!! All Outstanding First 50...", allOuts_first50);
    // console.log("PURCHASE !!! All Outstanding Second 50...", allOuts_second50);

    //console.log("PURCHASE !!! Total Balance...", totalBalance);

    //console.log("PURCHASE !!! All purchase...", allPurchase);
    //console.log("PURCHASE !!! All payments...", allPayments);
    // console.log("PURCHASE !!! All Woff...", allWoff);

    //console.log("All purchase with payments de Luis H....",allPurchaseInvoicesWithPayments)

    setAllPurchaseInvoicesWithPaymentsFull(allPurchaseInvoicesWithPayments);
    setAllOutsPurchaseData(allOuts);
    setTotalPurchasePaymentsByDate(allPayments);
    setTotalPurchasesBySupplierByDate(allPurchase);

    //setAllOutsData_first50(allOuts_first50);
    //setAllOutsData_second50(allOuts_second50);

  }

  //END Get all Purchase payments per Purchase Invoice -------------------------------------------------------------











  useEffect(() => {
    resetReportVariables();
  }, []);


  useEffect(() => {
    
    dispatch(
      supplierActions.updateSupplierAccount({

        // supplierAccount: {
        //   selectedSupplierTotalPurchases:totalPurchasesBySupplierByDate,
        //   selectedSupplierTotalPaid:totalPurchasePaymentsByDate,
        // },
          selectedSupplierTotalPurchases:totalPurchasesBySupplierByDate,
          selectedSupplierTotalPaid:totalPurchasePaymentsByDate,
        
        
        // selectedPurchaseInvoice: [
        //   {
        //     // "confirmationId":paymentInfo.CONFIRMATION_ID
        //     PURCHASEINVOICE_ID: purchasePaymentInfo.PURCHASEINVOICE_ID,
        //     PURCHASEINVOICE_NUMBER: purchasePaymentInfo.PURCHASEINVOICE_NUMBER,
        //     SUPPLIER_ID: purchasePaymentInfo.SUPPLIER_ID,
        //   },
        // ],
        //purchaseInvoiceAmountPaid: purchasePaymentInfo.AMOUNTPAID,   
        // purchasePaymentType: {
          //   //firstLetter: "B",
          //   PAYTYPE_ID: purchasePaymentInfo.PAYTYPE_ID,
          //   PAYTYPE: purchasePaymentInfo.PAYTYPE,
          //   PAYTYPE_REF: purchasePaymentInfo.PAYTYPE_REF,
          // },
          
          //paymentDateUpdated: paymentInfo.DATEONSTATEMENT,
          //purchasePaymentReference: purchasePaymentInfo.CHEQUEREF,
          //purchasePaymentDate: purchasePaymentInfo.DATEONSTATEMENT,
          //selectedPayment_id: purchasePaymentInfo.CLIENTPAYMENT_ID,
        })
  );

  //resetReportVariables();
}, [totalPurchasePaymentsByDate, totalPurchasesBySupplierByDate]); 
  
  
  const handleDatesChangesFromTo = (date, id) => {
    //console.log("value from the DatePicker", date);
    //setPaymentsByDateList([]);
    setPurchaseInvBySupplierByDate([]);
    setTotalPurchasePaymentsByDate(null);
    
    
    const dateIsValid = date !== null && date !== "Invalid Date";
    
    if (id === 0) {
      setSelectedCDateFrom(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: date,
        };
      }
    } else if (id === 1) {
      setSelectedCDateTo(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: date,
        };
      }
    } else {
      //console.log("error, date not usable only 2 options available");
      return;
    }

   
  };

  const handleGetReport_2 = ({ reportVariables }) => {
    if (
     
      selectedDateFrom !== null &&
      selectedDateTo !== null &&
      selectedDateFrom !== "Invalid Date" &&
      selectedDateTo !== "Invalid Date" &&
      Object.keys(selectedSupplier).length !== 0
    ) {
      // console.log(
      //   "datefrom",
      //   reportVariables.selectedDatesFrom,
      //   "---date to...",
      //   reportVariables.selectedDatesTo,
      //   "--supplier...",
      //   selectedSupplier
      // );
  
      getAllPurchaseInvBySupplierByDate(selectedDateFrom, selectedDateTo, selectedSupplier.SUPPLIER_ID);
      getAllPurchaseInvPaymentsBySupplierByDate(selectedDateFrom, selectedDateTo, selectedSupplier.SUPPLIER_ID);

      //ALL PAYMENTS FOR AN SPECIFIC PURCHASE INVOICE....
      //getAllPaidByOrder();

      

   

      setNotification(null);

    } else {


      setNotification("Please select valid data");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };


  //console.log("list of purchase Invoices by supplier by dates ...", purchaseInvBySupplierByDate);


  //on Supplier change set the supplier selected--------------------------------
  const handleSupplierChange = (event, value, name) => {
    //console.log("value from the function handleSuppliersChange...", value);

    //console.log("name from the function handleSuppliersChange...", name);

    //reset information displayed-------------
    setPurchaseInvBySupplierByDate([]);
    setTotalPurchasePaymentsByDate(null);

    setTotalPurchasePaymentsByDate(null);
    setTotalPurchasesBySupplierByDate(null);

    //dispatch to be updated....
    if (value) {
      //set the value to the selected supplier----
      setSelectedSupplier(value);

      dispatch(
        supplierActions.updateSupplier({
          [name]: value,
        })
      );
    } else {
      
      setSelectedSupplier({});
      dispatch(
        //supplierActions.updateSupplier({})
        supplierActions.resetSupplier({})
      );
      
    }
  };


  const box1Content = (
    <Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center"  }}
      >
        <Box
          //sx={{ display: "flex", flexDirection: matches2 ? "column" : "row" }}
          sx={{ display: "flex", flexDirection: matches3 ? "column" : "row" }}

        >


         {/* Suppliers List  */}
         <Autocomplete
              ref={AutoCompleteSuppliersList}
              //name="Suppliers"
              name="selectedSupplier"
              margin="auto"
              id="grouped-demo"
              options={suppliersListFiltered.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.SUPPLIER_NAME}
              sx={
                matches3
                  ? { width: "100%", margin: "0.2em" }
                  : { width: 350, margin: "1em" }
              }
              onChange={(event, value, name) =>
                handleSupplierChange(
                  event,
                  value,
                  AutoCompleteSuppliersList.current.getAttribute("name")
                )
              }
              //onSelect={alert("selected")}
              renderInput={(params) => (
                <MyTextField {...params} label="Suppliers" />
              )}
            />   



          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="From"
            value={selectedDateFrom}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches3 ? "0.2em" : "1em" }}
              />
            )}
          />

          <Box
            sx={{
              margin: matches3 ? "0.2em" : "1em",
              textAlign: "center",
            }}
          >
            {" "}
            to{" "}
          </Box>

          <DatePicker
            label="To"
            value={selectedDateTo}
            onChange={(date) => handleDatesChangesFromTo(date, 1)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches3 ? "0.2em" : "1em" }}
              />
            )}
          />

          <Button
            onClick={() => handleGetReport_2({ reportVariables })}
            variant="outlined"
            sx={{
              margin: matches3 ? "0.2em" : "1em",
              color: "#7a1125",
              borderColor: "#7a1125",
              height: "3.2rem",
              "&:hover": {
                borderColor: "#270c10",
                color: "#fce9ec",
                backgroundColor: "#7a1125",
              },
            }}
          >
            GO
          </Button>
        </Box>

        {/* Button to change from Bank statements to recorded date for payment list---------------------- */}
        <Box
          sx={{
            display: "flex",
            //flexDirection: matches ? "column" : "row",
            flexDirection: "column",
            //alignItems: "flex-start",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {showPayDate ? (
              <ReceiptIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            ) : (
              <ComputerIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            )}

            <Typography
              sx={{
                p: 1,
                //backgroundColor: "#e7dddd",
                color: theme.palette.common.ludisMain2,
                fontSize: matches3 ? 16 : 22,
              }}
            >
              {showPayDate ? "Pay Date" : "Invoice Date"}
            </Typography>
          </Box>

          <Button
            onClick={(event) => {
              event.preventDefault();
              showPayDateHandler(event);
            }}
            sx={{
              color: "#7A1125",
              textTransform: "capitalize",
              marginBottom: 2,
            }}
          >
            {showPayDate ? (
              <>
                <ComputerIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Invoice Date
                </Typography>
              </>
            ) : (
              <>
                
                <ReceiptIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Pay Date
                </Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );

  //Box to show Payments list for the dates selected-----------------------------------------
  const box3Content = (
    // <PaymentsReportByDate
    <PurchaseInvoicesReportData
      purchaseInvBySupplierByDate={purchaseInvBySupplierByDate}
      purchaseInvPaymentsBySupplierByDate={purchaseInvPaymentsBySupplierByDate}  
      purchasePaymentsTypeList = {purchasePaymentsTypeList}
      allPurchaseInvoicesWithPaymentsFull = {allPurchaseInvoicesWithPaymentsFull}
      isLoading1={isLoading1}
      showPayDate={showPayDate}
      matches={matches}
      isUpdatedPurchasePayment={isUpdatedPurchasePayment} 
      updatedPurchasePayment={updatedPurchasePayment} 
      //isNewPurchasePayment = {isNewPurchasePayment}
      socket={socket}
      
    />
  );

  //Box to Show Summary report on payments for the dates selected-----------------------------
  const box2Content =
      purchaseInvBySupplierByDate.length > 0 ? (

      // <PaymentsIn
      //   // tabInitialDate={selectedDateFrom}
      //   // tabFinalDate={selectedDateTo}
      //   tabInitialDate={new Date(selectedDateFrom).toISOString()}
      //   tabFinalDate={new Date(selectedDateTo).toISOString()}
      //   showPayDate={showPayDate}
      //   updatePayment={updatedPayment} 
      // />


      // <Deposits
      //   title="Total Supplier"
      //   totalPaymentsByDate={totalPurchasePaymentsByDate}
      //   // totalStandardPaymentsByDate={totalStandardPaymentsByDate}
      //   // totalPaymentsUnallocatedByDate={totalPaymentsUnallocatedByDate}
      //   // paymentsByTypeByDateList={paymentsByTypeByDateList}
      //   //paymentsUnallocatedByTypeByDateList={paymentsUnallocatedByTypeByDateList}
      //   //changeDates={changeDates}
      //   changeDates=""

      //   //changeDatesHandler={changeDatesHandler}
      //   changeDatesHandler=""

      //   icon={
      //     <CreditScoreIcon
      //       sx={{
      //         fontSize: 20,
      //         color: "#4E1721",
      //         marginRight: 2,
      //         marginTop: -1,
      //       }}
      //     />
      //   }
      // />

       
      //"testing..."

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Total Invoices</Title>
        <Divider sx={{ width: "10vw" }} />
        <PurchaseReportSummary  isLoading1 = {isLoading1}  />
        
      </Box>

  




    ) : (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Total Invoices</Title>
        <Divider sx={{ width: "10vw" }} />
        <GradingIcon
          sx={{
            color: theme.palette.common.ludisMain,
            fontSize: 50,
            marginTop: 5,
          }}
        />
      </Box>
    );

  useEffect(() => {
    if (selectedDateFrom !== null && selectedDateTo !== null) {
      handleGetReport_2({ reportVariables });
    }
  }, [showPayDate, updatedPurchasePayment ]);

  useEffect(() => {
      //if (purchaseInvBySupplierByDate?.length > 0 && purchaseInvPaymentsBySupplierByDate?.length > 0) {  //Before fixing the issue to show invoices when there is no previous payments...
    if (purchaseInvBySupplierByDate?.length > 0) {

     getAllPaidByPurchaseInvoice();
   } else {
     //console.log("array empty for Purchase Invoices by supplier by date / payments...");
     //console.log("purchaseInvPaymentsBySupplierByDate", purchaseInvPaymentsBySupplierByDate)
     //console.log("purchaseInvBySupplierByDate", purchaseInvBySupplierByDate)

     //test
     setAllPurchaseInvoicesWithPaymentsFull([])
     //end test

   }

   //clean up function
   return () => console.log("clean up N.2");
 }, [purchaseInvBySupplierByDate, purchaseInvPaymentsBySupplierByDate]);



  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <DashboardContainerReport
        box1={box1Content}
        //box1={<h1>Box 1 info here....</h1>}
        box2={box2Content}
        box3={box3Content}
        box4={<h6>Box 4 info here....</h6>}
        matches={matches}
        matches2={matches2}
        matches3={matches3}
        notification={notification}
        ListFiltered={consultantListFiltered}
        handleListItemChange=""
        handleGetReport_2={handleGetReport_2}
        reportVariables={reportVariables}
        IsLoading={false}
        reportTitle="Purchase Invoices  ..."
        reportId={2} //Id for payment report so do not load consultant list
        boxHeight={300}
        boxHeightMatch={600}
        boxSize={3}
        myWidth="65vw"
      />

      
    </LocalizationProvider>
  );
}
