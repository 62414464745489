import axios from 'axios';
import { variables } from '../../Variables';

//const API_BASE_URL = 'http://localhost:5000/property';

export const getProjects = async (project_id ) => {
  //console.log("this is the project id in the api...",project_id)
  try {
    //setLoadingMap(true)
    const response = await axios.get(
        variables.API_URL + "projects" + "/" + project_id,  //zero to get all projects, 
        { withCredentials: true }
    );

    return response;

    
  } catch (error) {
    console.error("Error fetching Projects URL:", error);
    throw error;
  }finally{
    //setLoadingMap(false)
  }
};


//-----
  //fetching projects from DB-------------------------------------------
//   const getProjects = (project_id) => {
//     axios.get(
//         variables.API_URL + "projects" + "/" + project_id,  //zero to get all projects, 
//         { withCredentials: true }
//     )

//       .then((res) => {
//         console.log("fetching projects...",res.data.projectListInfo)
//         //setProjectList(res.data.projectListInfo);
//         //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

