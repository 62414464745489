// import React from 'react';
// import { Typography, Grid, Paper } from '@mui/material';
// import { brown } from '@mui/material/colors';

// export default function InvoiceCreator_Step3({ invoiceData = {} }) {
//   return (
//     <Paper style={{ padding: '20px', backgroundColor: brown[50] }}>
//       <Typography variant="h6" gutterBottom style={{ color: brown[800] }}>
//         Invoice Summary
//       </Typography>
//       <Grid container spacing={2}>
//         {Object.entries(invoiceData).map(([key, value]) => (
//           <Grid item xs={12} sm={6} key={key}>
//             <Typography variant="subtitle1" style={{ color: brown[700] }}>
//               {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}:
//             </Typography>
//             <Typography variant="body1">{value || 'N/A'}</Typography>
//           </Grid>
//         ))}
//       </Grid>
//     </Paper>
//   );
// }

import React, {Fragment} from 'react';

//My Imports-----------------------------------
import Title from "../Title";
import theme from "../UI/Theme";

//MUI Imports--------------------------------------------------
import { Typography, Paper } from '@mui/material';
import { brown } from '@mui/material/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from "@mui/material/Box";
import { PropagateLoader } from "react-spinners";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import ArticleIcon from "@mui/icons-material/Article";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { GridLoader } from "react-spinners";


export default function InvoiceCreator_step3({ loadingSavingPo, resState }) {

    //console.log("here is the resState",resState)

    const matches = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Fragment>
      {loadingSavingPo ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="6" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 
    //   Object.keys(resState).length !== 0 ? (
        (resState) ? (

        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {resState.success ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: matches ? 0 : "2rem",
                marginTop: matches ? 0 : "2rem",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
              <Title sx={{ margin: "auto" }}>
                PURCHASE INVOICE SUCCESSFULLY RECORDED !
              </Title>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: matches ? 0 : "2rem",
                marginTop: matches ? 0 : "2rem",
              }}
            >
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
              <Title sx={{ margin: "auto" }}>
                PURCHASE INVOICE NOT SAVED, CONTACT YOUR ADMIN TEAM !
              </Title>
            </Box>
          )}

          <ListItem>
            {resState.success ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}

            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="purchase Invoice Status:"
              secondary={resState.message ? resState.message : "No Info..."}
            />
          </ListItem>
        </Box>
      ) : (
        <Box
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="6" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
        // <Paper
        //   style={{
        //     padding: "20px",
        //     backgroundColor: brown[50],
        //     textAlign: "center",
        //   }}
        // >
        //   <CheckCircleOutlineIcon style={{ fontSize: 60, color: brown[500] }} />
        //   <Typography
        //     variant="h5"
        //     gutterBottom
        //     style={{ color: brown[800], marginTop: "20px" }}
        //   >
        //     Invoice Submitted Successfully!
        //   </Typography>
        //   <Typography variant="body1" style={{ color: brown[600] }}>
        //     Your invoice has been created and saved. You can view it in your
        //     invoice list.
        //   </Typography>
        // </Paper>
      )}
    </Fragment>
  );
}

