import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { variables } from '../../Variables';

import { useSelector} from "react-redux";

import { parse, isValid, format } from 'date-fns';

//MUI IMPORTS-------------------------------------------------------------------------
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stepper, Step, StepLabel, LinearProgress, Typography, Box } from '@mui/material';
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Description, AssignmentTurnedIn, CheckCircle } from '@mui/icons-material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import BackupIcon from '@mui/icons-material/Backup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import InvoiceStep1 from './InvoiceStep1';
//import InvoiceSummary from './InvoiceSummary';
//import InvoiceConfirmation from './InvoiceConfirmation';
import InvoiceCreator_step1 from './InvoiceCreator_Step1';
import InvoiceCreator_step2 from './InvoiceCreator_Step2';
import InvoiceCreator_step3 from './InvoiceCreator_step3';
import { getProjects } from '../generalFunctions/getProjects'
//import theme from "../UI/Theme";
import { customColors, customStyles } from '../UI/Theme_v2';

//Outsidse Functions------------------------------------------------------
function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


  
  const steps = [
    { label: 'Inv. Details', icon: <Description sx={{color: customColors.ludisMain}} />, iconCompleted: <Description sx={{color: customColors.ludisMain}}/> },
    { label: 'Summary', icon: <AssignmentTurnedIn sx={{color: customColors.ludisLight}}/>, iconCompleted: <Description sx={{color: customColors.ludisMain}}/> },
    { label: 'Saved', icon: <CheckCircle sx={{color: customColors.ludisLight}} /> , iconCompleted: <Description sx={{color: "#4E1721"}}/> }
  ];

export default function InvoiceCreator({PoSelected, isUpdatedPurchaseInvoice, socket, matches3}) {
  const currentUserInfo = useSelector((state) => state.user);
  //console.log("POselected here...", PoSelected);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: "",
    netAmount: "",
    taxAmount: "",
    totalAmount: "",
    projectId: "",
    projectName: "",
    purchaseOrder_id: "",
    purchaseOrder_ref: "",
    description: "",
    invoiceDate: "",
    invoicePayDate: "",
    supplierId: "",
    purchaseOrder_ProductId: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [loadingSavingPo, setLoadingSavingPo] = useState(false);
  const [resState, setResState] = useState(null); //state to display success or error message rom the API

  //Function to get all info for PO selected ------------------------------------------------------------------------------
  const getPoSelectedInfo = async () => {
    const poSelected_Project = await getProjects(PoSelected.COSTALLOCATED_ID);
    const poSelected_Project_name = poSelected_Project?.data.projectListInfo[0];

    //console.log("this is the project selected...",poSelected_Project?.data.projectListInfo[0])
    //console.log("this is the PO selected object..", PoSelected);

    const MyPoSelected = {
      purchaseOrder_id: PoSelected.PoSelected,
      purchaseOrder_ref: PoSelected.PURCHASEORDER_REF,
      purchaseOrder_ProductId: PoSelected.PURCHASEPRODUCT_ID,
      netAmount: parseFloat(PoSelected.PURCHASEORDER_NETPRICE),
      taxAmount: parseFloat(PoSelected.PoSelected_vat),
      totalAmount:parseFloat(PoSelected.PURCHASEORDER_NETPRICE + PoSelected.PURCHASEORDER_VAT),
      projectId: PoSelected.COSTALLOCATED_ID,
      projectName:
        poSelected_Project_name.CLUB_NAME +
        `(${poSelected_Project_name.CLUB_CODE})  ${poSelected_Project_name.YEAR}`,
      description: PoSelected.PURCHASE_DESCRIPTION,
      supplierId: PoSelected.PoSelected_supplier,
      supplierName: PoSelected.SUPPLIER_NAME,
    };

    setInvoiceData((prevData) => ({
      ...prevData,
      ...MyPoSelected,
    }));
  };

  //End Function to get all info for PO selected ---------------------------------------------------------------------------

  //Function to save the notification in Mongo ----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"
  
  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
      if(res.status === 201){
        
        //getPost()
      }
    })
  }
  //END Function to save the notification in Mongo ----------------------------------------------------------------------
  //Notification handler ------------------------------------------------------------------------------------------------
    //handle notifications
    const submitNotification = (info) => {
  
  
      socket.emit("sendNotification", {
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: currentUserInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails_Purchase: {
          "purchaseInvoice": info.notificationDetails_Purchase.purchaseInvoice, 
          "purchaseInvoiceId": info.notificationDetails_Purchase.purchaseInvoiceId,
          "supplier":info.notificationDetails_Purchase.supplier,
          "supplierId":info.notificationDetails_Purchase.supplierId,
          "purchaseTotal":info.notificationDetails_Purchase.purchaseTotal,
          "isPurchaseInvoice": info.notificationDetails_Purchase.isPurchaseInvoice,
      }
    })
  
      //Saving in the DB Mongo Notifications--------------------------------------
      //get the objetc for the Notification Content---------------------------------
      const noty = {
        senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
        senderPic: currentUserInfo.userPicURL,
        receiverName: "All",
        type: info.type,
        name: info.name,
        notificationDate: info.notificationDate,
        notificationTime: info.notificationTime,
        notificationDetails:{  
          "purchaseInvoice": info.notificationDetails_Purchase.purchaseInvoice, 
          "purchaseInvoiceId": info.notificationDetails_Purchase.purchaseInvoiceId,
          "supplier":info.notificationDetails_Purchase.supplier,
          "supplierId":info.notificationDetails_Purchase.supplierId,
          "purchaseTotal":info.notificationDetails_Purchase.purchaseTotal,
          "isPurchaseInvoice": info.notificationDetails_Purchase.isPurchaseInvoice,
      },
        // receiverName: "All",
      }
      createPost(noty)
  
  
  
  
  };
  //END Notification handler---------------------------------------------------------------------------------------------

  //Function to Reset the Data----------------------------------------------------------------------------------------------
  const resetInvoiceData = () => {
    setInvoiceData({
      invoiceNumber: "",
      netAmount: "",
      taxAmount: "",
      totalAmount: "",
      projectId: "",
      projectName: "",
      purchaseOrder_id: "",
      purchaseOrder_ref: "",
      description: "",
      invoiceDate: "",
      invoicePayDate: "",
      supplierId: "",
      purchaseOrder_ProductId: "",
    });
  };
  //End Function to Reset the Data------------------------------------------------------------------------------------------

  useEffect(() => {
    getPoSelectedInfo();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    //   setInvoiceData({
    //     invoiceNumber: '',
    //     netAmount: '',
    //     taxAmount: '',
    //     totalAmount: '',
    //     projectId: '',
    //     purchaseOrder: '',
    //     description: '',
    //     invoiceDate: '',
    //     invoicePayDate: ''
    //   });
  };

  const handleNext = () => {
    //console.log("step handle next", activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData((prevData) => {
      const newData = { ...prevData, [name]: value };
      //console.log("this is the new data...",newData)
      validateForm(newData);
      return newData;
    });
  };

  const validateForm = (data) => {
    const requiredFields = [
      "invoiceNumber",
      "netAmount",
      "taxAmount",
      "totalAmount",
      "projectId",
      "description",
      "invoiceDate",
      "invoicePayDate",
    ];
    //console.log("requiredFlies here...",requiredFields)
    const isValid = requiredFields.every(
      (field) => String(data[field]).trim() !== ""
    );
    //const isValid = requiredFields.every(field => data[field].trim() !== '');
    setIsFormValid(isValid);
  };

  // Helper function to convert date from DD.MM.YYYY to YYYY-MM-DD
  // function convertDateToMySQLFormat(dateString) {
  //   if (!dateString) return null;
  //   const parsedDate = parse(dateString, "dd.MM.yyyy", new Date());
  //   return format(parsedDate, "yyyy-MM-dd");
  // }

  const handleSubmit = () => {
    setLoadingSavingPo(true);

    //sending data to the backend-----------------------------------------
    const config = {
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const purchaseInvoiceData = {
      // SUPPLIER_ID: invoiceData.PoSelected_supplier,
      SUPPLIER_ID: invoiceData.supplierId,
      PURCHASEINVOICE_DATE: invoiceData.invoiceDate,
      PURCHASEINVOICE_NUMBER: invoiceData.invoiceNumber,
      PURCHASEINVOICE_DESCRIPTION: invoiceData.description,
      PURCHASEINVOICE_NETPRICE: invoiceData.netAmount,
      PURCHASEINVOICE_VAT: invoiceData.taxAmount,
      PURCHASEINVOICE_TOTAL:parseFloat(invoiceData.netAmount) + parseFloat(invoiceData.taxAmount),
      PURCHASEINVOICE_PAYDAY: invoiceData.invoicePayDate,
      PURCHASEINVOICE_STATUS: "1",
      PURCHASEPAYMENTSTATUS_ID: "0",
      PURCHASEINVOICE_FILE: "1",
      PURCHASEINVOICE_COMMENTS: "",
      PURCHASEINVOICE_PIC: "",
      M_USER: currentUserInfo.userId,
      TIME: new Date(),
    };

    // console.log(
    //   "purchase invoice data before the axios call....",
    //   purchaseInvoiceData
    // );

    const purchaseOrderListData = {
      purchaseOrder_id: invoiceData.purchaseOrder_id,
      purchaseOrder_ProductId: invoiceData.purchaseOrder_ProductId,
    };

    try {
      axios
        .post(
          variables.API_URL + "purchase/newpurchaseinvoice/",
          { purchaseInvoiceData, purchaseOrderListData },
          config
        )
        .then((response) => {
          setLoadingSavingPo(false);
          //console.log("respondse...", response.data);
          //console.log("responding ok", response.data.success);
          setResState(response.data);
          //checkIsNewPayment(Math.random()) //send the paymentID to the parent component to refresh the list of payments including new one-----
          //Notification code---------------------------------------------------------------
          //Submit the notification to the socket  - notificationType = 5 for New Purchase Supplier Invoice..............
          submitNotification({
            //type: 1,
            type: { generalType: 1, notificationType: 8 },
            name: "Purchase Invoice",
            notificationDate: new Date().toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            notificationTime: new Date().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
            notificationDetails_Purchase: {
              purchaseInvoice: invoiceData.invoiceNumber,
              purchaseInvoiceId: response.data.purchaseInvoiceId,
              supplier: invoiceData.supplierName,
              supplierId: invoiceData.supplierId,
              purchaseTotal:
                parseFloat(invoiceData.netAmount) +
                parseFloat(invoiceData.taxAmount),
              isPurchaseInvoice: true,
            },
            //"purchasePayment" : currentPurchasePaymentInfo.purchaseInvoiceAmountPaid,
          });
          //End Notification Code-----------------------------------------------------------
          setTimeout(() => {
            //console.log("my response inside....", response);
            setOpen(false);
            resetInvoiceData();
            //setActiveStep(0)
            //   setFormData({
            //     paymentDate: "",
            //     paymentAmount: "",
            //     property: "",
            //     month: "",
            //   });
            setResState(null);
            //send the paymentID to the parent component to refresh the list of payments including new one-----
            isUpdatedPurchaseInvoice(Math.random());
          }, 1500);
        })
        .catch(function (error) {
          console.log("Axios error ...error here...", error);
          setLoadingSavingPo(false);
        });
    } catch (error) {
      console.log("error en el catch....", error);
      setLoadingSavingPo(false);
    }

    //End sending data to backend-----------------------------------------

    //console.log("this is the step should be last ...",activeStep)

    // Here you would typically send the data to your backend
    //console.log('Submitting invoice data:', invoiceData);
    handleNext();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <InvoiceCreator_step1
            invoiceData={invoiceData}
            handleInputChange={handleInputChange}
          />
        );
      case 1:
        return <InvoiceCreator_step2 invoiceData={invoiceData} matches3={matches3} />;
      case 2:
        return (
          <InvoiceCreator_step3
            loadingSavingPo={loadingSavingPo}
            resState={resState}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Button
        //variant="contained"
        //color="primary"
        onClick={handleClickOpen}
        sx={{
          //color: theme.palette.common.ludisMain,
          color: "#4E1721",
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <ManageAccountsIcon
          sx={{
            fontSize: 25,
            color: "#4E1721",
            marginLeft: 1,
          }}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        //aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        style={customStyles.dialog}
      >
        <DialogTitle id="form-dialog-title" style={customStyles.dialogTitle}>
          Create New Purchase Invoice
        </DialogTitle>
        <DialogContent
          style={{
            //backgroundColor: customColors.background,
            backgroundColor: customColors.background_white,
            paddingTop: "20px",
          }}
        >
          <Box sx={{ width: "100%", mb: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(activeStep / (steps.length - 1)) * 100}
              sx={customStyles.linearProgress}
              // sx={{
              //   height: 10,
              //   borderRadius: 5,
              //   backgroundColor: brown[100],
              //   "& .MuiLinearProgress-bar": {
              //     backgroundColor: "#4E1721",
              //   },
              // }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography
                variant="body2"
                style={{ color: customColors.text.secondary }}
              >
                Step {activeStep + 1} of {steps.length}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: customColors.text.secondary }}
              >
                {Math.round((activeStep / (steps.length - 1)) * 100)}% Complete
              </Typography>
            </Box>
          </Box>
          {/* <Stepper activeStep={activeStep} style={{ margin: "20px 0" }}>
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconProps={{
                      style: customStyles.stepIcon,
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper> */}
          <Stepper activeStep={activeStep} style={{ margin: "20px 0" }}>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        color:
                          activeStep >= index
                            ? customColors.ludisMain
                            : customColors.secondary,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {activeStep >= index ? step.iconCompleted : step.icon}

                      {/* {React.cloneElement(step.icon, {
                          style: { fontSize: "1.5rem" },
                        })} */}
                    </div>
                  )}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* <Stepper activeStep={activeStep} style={{ margin: "20px 0" }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper> */}
          {getStepContent(activeStep)}
        </DialogContent>
        <DialogActions style={{ backgroundColor: customColors.background }}>
          <Button
            onClick={handleClose}
            style={{ color: customColors.text.secondary }}
          >
            <span style={{ color: customColors.text.secondary }}>
              Cancel
              <CancelIcon
                sx={{
                  fontSize: 30,
                  color: customColors.ludisLight,
                  marginLeft: 1,
                }}
              />
            </span>
          </Button>
          {activeStep > 0 && (
            <Button
              onClick={handleBack}
              style={{ color: customColors.text.secondary }}
            >
              <span style={{ color: customColors.text.secondary }}>
                Back
                <ArrowCircleLeftIcon
                  sx={{
                    fontSize: 25,
                    //color: "#4E1721",
                    color: customColors.ludisLight,
                    marginLeft: 1,
                  }}
                />
              </span>
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button
              onClick={activeStep === 1 ? handleSubmit : handleNext}
              //color="primary"
              //variant="contained"
              //disabled={activeStep === 0 && !isFormValid}
              // style={{
              //   ...customStyles.button,
              //   opacity: activeStep === 0 && !isFormValid ? 0.5 : 1,
              // }}
              disabled={activeStep === 0 && !isFormValid}
            >
              {/* {activeStep === 1 ? "Submit" : "Next"} */}
              {activeStep === 1 ? (
                <span style={{ color: customColors.text.secondary }}>
                  Save
                  <BackupIcon
                    sx={{
                      fontSize: 30,
                      color: "#4E1721",
                      marginLeft: 1,
                    }}
                  />
                </span>
              ) : (
                <span style={{ color: customColors.text.secondary }}>
                  Next
                  <ArrowCircleRightIcon
                    sx={{
                      fontSize: 30,
                      color: "#4E1721",
                      marginLeft: 1,
                    }}
                  />
                </span>
              )}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
