import React, {useState} from 'react';

//MUI IMPORTS-----------------------------------------------------------
import { TextField, Grid } from '@mui/material';
import { Description, CalendarToday } from '@mui/icons-material';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { brown } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parse, isValid, format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de'; // German locale for European date format

//MY IMPORTS----------------------------------------------------------
import { customColors, customStyles } from '../UI/Theme_v2';


export default function InvoiceStep1({ invoiceData = {}, handleInputChange }) {


  //test code---------------------------------------------------------------------
  //end test code-----------------------------------------------------------------




  const handleDateChange = (name) => (date) => {
    handleInputChange({ 
      target: { 
        name, 
        //value: date && isValid(date) ? format(date, 'dd.MM.yyyy') : '' 
        value: date && isValid(date) ? format(date, 'yyyy-MM-dd') : '' 
      } 
    });
  }

      const parseDateString = (value) => {
        if (!value) return null;
        const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
        return isValid(parsedDate) ? parsedDate : null;
      };
    // const handleDateChange = (name) => (date) => {
    //   handleInputChange({
    //     target: { name, value: date ? date.toISOString().split("T")[0] : "" },
    //   });
    // };
    //console.log("this is the invocie data...", invoiceData)

    //Date Formatting------------------------------------------------------------
    // const localeMap = {
    //     en: enLocale,
    //     fr: frLocale,
    //     de: deLocale,
    //   };
      
      // const maskMap = {
      //   fr: "__/__/____",
      //   en: "__/__/____",
      //   de: "__.__.____",
      // };

      const [locale, setLocale] = useState("de");
    //Date Formatting -------------------------------------------------------------

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
    {/* // <LocalizationProvider
    //   dateAdapter={AdapterDateFns}
    //   locale={localeMap[locale]}
    // > */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            // mask={maskMap[locale]}
            // format="YYY/MM/dd"
            label="Invoice Date"
            value={parseDateString(invoiceData.invoiceDate)}
            onChange={handleDateChange('invoiceDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputLabelProps={{
                  style: { color: brown[700] },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: '0.9rem', fontWeight: 'bold' },
                  startAdornment: (
                    <>
                      <CalendarToday fontSize="small" style={customStyles.icon} />
                      {params.InputProps?.startAdornment}
                    </>
                  ),
                }}
                sx={{...customStyles.input, width:'10px'}}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
        <TextField
          name="invoiceDate"
          label="Invoice Date"
          type="date"
          value={invoiceData.invoiceDate || ''}
          onChange={handleInputChange}
          fullWidth
          required
          InputLabelProps={{shrink: true,}}
          InputProps={{
            style: { fontSize: '0.9rem', fontWeight: '500' },
            //startAdornment: <CalendarToday fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
          }}
        />
      </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="invoiceNumber"
            label="Invoice Number"
            value={invoiceData.invoiceNumber || ""}
            onChange={handleInputChange}
            fullWidth
            required
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <Description fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="projectId"
            label="Project"
            value={invoiceData.projectName || ""}
            onChange={handleInputChange}
            fullWidth
            required
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.7rem", fontWeight: "500" },
              //startAdornment: <Description fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <DatePicker
            //mask={maskMap[locale]}
            //format="YYY/MM/dd"
            // label="From"
            // value={invoiceData.invoicePayDate}
            // onChange={(date) => handleDateChange("invoicePayDate")}
            label="Invoice Pay Date"
            value={parseDateString(invoiceData.invoicePayDate)}
            onChange={handleDateChange('invoicePayDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ margin:  "1em" }}
              />
            )}
          />
          {/* <DatePicker
            label="Invoice Pay Date"
            value={
              invoiceData.invoicePayDate
                ? new Date(invoiceData.invoicePayDate)
                : null
            }
            onChange={}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputLabelProps={{
                  style: { color: brown[700] },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: "0.8rem", fontWeight: "bold" },
                  startAdornment: (
                    <>
                      <CalendarToday
                        fontSize="small"
                        style={{ color: brown[500], marginRight: "8px" }}
                      />
                      {params.InputProps?.startAdornment}
                    </>
                  ),
                }}
                sx={customStyles.input}
              />
            )}
          /> */}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            name="invoicePayDate"
            label="Invoice Pay Date"
            type="date"
            value={invoiceData.invoicePayDate || ""}
            onChange={handleInputChange}
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <CalendarToday fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            name="purchaseOrder"
            label="Purchase Order"
            value={"PO -" + invoiceData.purchaseOrder_ref || ""}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <Description fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            value={invoiceData.description || ""}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
            required
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <Description fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="netAmount"
            label="Net Amount"
            value={invoiceData.netAmount || ""}
            onChange={handleInputChange}
            fullWidth
            required
            type="number"
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <CurrencyPoundIcon fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="taxAmount"
            label="VAT "
            value={parseFloat(invoiceData.taxAmount) || parseFloat("0")}
            onChange={handleInputChange}
            fullWidth
            required
            type="number"
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <CurrencyPoundIcon fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="totalAmount"
            label="Total Amount"
            value={invoiceData.totalAmount || ""}
            onChange={handleInputChange}
            fullWidth
            required
            type="number"
            InputLabelProps={{
              style: { color: customColors.primary, fontSize: "0.9rem" },
            }}
            InputProps={{
              style: { fontSize: "0.8rem", fontWeight: "500" },
              //startAdornment: <CurrencyPoundIcon fontSize="small" style={{ color: "#4E1721", marginRight: '8px' }} />,
            }}
            sx={customStyles.input}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
        <TextField
          name="invoicePayDate"
          label="Invoice Pay Date"
          type="date"
          value={invoiceData.invoicePayDate || ''}
          onChange={handleInputChange}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid> */}
      </Grid>
    </LocalizationProvider>
  );
}

