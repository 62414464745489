import {Fragment, useState} from 'react';
import { MyTable, MyTableRow, StyledTableCell, MyTableCell } from "../UI/styledComponents/StyledComponents";

import theme from "../UI/Theme";
import Title from "../Title";
import {TablePaginationActions} from "../UI/Pagination";

//MUI IMPORTS-----------------------------------------------------------------
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

//import { current } from "@reduxjs/toolkit";
//import TableCell, { tableCellClasses } from "@mui/material/TableCell";
//import TableCell from "@mui/material/TableCell";
//import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
//import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
//import Button from "@mui/material/Button";
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
// import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
// import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
// import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
// import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';


const PaymentsOutInfo = (props)=>{

    const {currentPaymentsOutInfo} = props;

    //console.log("OUR PROPS for payment out info.....", currentPaymentsOutInfo)

    //const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    //const matches = useMediaQuery(theme.breakpoints.down("md"));

    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));




    const [open1, setOpen1] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    //const repDate= new Date();
    //const repDate1= new Date();

    //Functions for Table Pagination--------------------------------------
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentPaymentsOutInfo.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      
    };
    //END Functions for TAble Pagination----------------------------------


    const handleCollapse = (event, item) => {
    event.preventDefault();
    //console.log("el eventoooooo....", event.currentTarget);
    //console.log("el eventoooooote....", item.PURCHASEINVOICE_ID);

    setOpen1((prev) => ({
      ...prev,
      [item.PAYOUT_ID]: !prev[item.PAYOUT_ID],
    }));

    //test to be deleted------
    //paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };


    
    return(
        <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
              
            }}
          >
            <Title>Purchase Invoices Paid ...</Title>

            <Divider sx={{ width: "60vw" }} />

            {/* {purchaseInvBySupplierByDate?.length > 0 ? ( */}
            {currentPaymentsOutInfo?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: matches3?"70vw":matches2 ? "60vw" : "60vw", marginTop: 2,  }}
                // sx={{ width: matches2 ? "60vw" : "60vw", marginTop: 2 }}
              >
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">...</StyledTableCell>
                      {/* <StyledTableCell align="left">Date</StyledTableCell> */}
                      <StyledTableCell align="left">Pay Date</StyledTableCell>
                      <StyledTableCell align="left">Inv.Noxxx.</StyledTableCell>
                      <StyledTableCell align="left">Supplier</StyledTableCell>
                      {/* <StyledTableCell align="right">N.Days(PayDay)</StyledTableCell> */}
                      {/* <StyledTableCell align="right">Net &nbsp;(£)</StyledTableCell> */}
                      {/* <StyledTableCell align="right">Vat</StyledTableCell>
                      <StyledTableCell align="right">Total</StyledTableCell> */}
                      <StyledTableCell align={matches3?"left":"right"}>Paid</StyledTableCell>
                      {/* <StyledTableCell align="right">Credited</StyledTableCell> */}
                      {/* <StyledTableCell align="right">P.O</StyledTableCell> */}
                      {/* <StyledTableCell align="right">Status</StyledTableCell> */}
                      {/* <StyledTableCell align="right">...</StyledTableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {/* {purchaseInvBySupplierByDate.map((item) => { */}
                    {
                    (rowsPerPage > 0? currentPaymentsOutInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):
                    currentPaymentsOutInfo).map((item) => {

                      //console.log("this is the item inside the map.....",item)

                    //   showPayDate
                    //     ? (repDate = item.pOrder.PURCHASEINVOICE_PAYDAY)
                    //     : (repDate = item.pOrder.PURCHASEINVOICE_DATE);
                    //   showPayDate
                    //     ? (repDate1 = item.pOrder.PURCHASEINVOICE_DATE)
                    //     : (repDate1 = item.pOrder.PURCHASEINVOICE_PAYDAY);
                      return (
                        <Fragment>
                          <MyTableRow key={item.PAYOUT_ID}>
                            <MyTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                              >
                                {open1[item.PAYOUT_ID] ? (
                                  <KeyboardArrowUpIcon sx={{fontSize:matches3?"0.6rem":"1rem", padding:0}} />
                                ) : (
                                  <KeyboardArrowDownIcon sx={{fontSize:matches3?"0.6rem":"1rem", padding:0}} />
                                )}
                              </IconButton>
                            </MyTableCell>

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {new Date(repDate).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {item.PURCHASEINVOICE_NUMBER}
                              </Typography>
                            </TableCell> */}

                            <MyTableCell align="left">
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                                {new Date(
                                  item.PAYDATE
                                ).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  //year: "numeric",
                                })}
                              </Typography>
                            </MyTableCell>

                            <MyTableCell align="left">
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                                {
                                matches3?item.PURCHASEINVOICE_NUMBER.substring(0, 10):
                                matches2?item.PURCHASEINVOICE_NUMBER.substring(0, 20):
                                item.PURCHASEINVOICE_NUMBER.substring(0, 30)
                              }
                              </Typography>
                            </MyTableCell>

                              {/* £{" "} */}
                            {/* <TableCell>
                              {parseFloat(
                                item.PURCHASEINVOICE_NETPRICE
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell> */}

                              {/* £{" "} */}
                            {/* <TableCell>
                              {parseFloat(
                                item.PURCHASEINVOICE_VAT
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell> */}

                            <MyTableCell align="left">
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                                {
                                matches3?item.SUPPLIER_NAME.substring(0, 10):
                                matches2?item.SUPPLIER_NAME.substring(0, 20):
                                item.SUPPLIER_NAME.substring(0, 30)
                                }
                              </Typography>
                            </MyTableCell>

                            <MyTableCell align={matches3?"left":"right"}  >
                            <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                              £{" "}
                              {parseFloat(
                                item.TOTALPAID).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                              </Typography>
                            </MyTableCell>

                              {/* £{" "} */}
                            {/* <TableCell>
                              {parseFloat(
                                item.totalPaid
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {(item.PURCHASEINVOICE_VAT + item.PURCHASEINVOICE_NETPRICE - item.totalPaid)===0 && 
                                <CheckCircleOutlineIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.okGreen,
                                  marginLeft: 1,
                                }}
                              />}

                               {(item.PURCHASEINVOICE_VAT + item.PURCHASEINVOICE_NETPRICE - item.totalPaid) < 0 && 
                                <ReportGmailerrorredIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.ludisRed2,
                                  marginLeft: 1,
                                }}
                              />}


                              </Typography>
                            </TableCell> */}

                            {/* <TableCell>
                              <Typography
                                aria-owns={
                                  open ? "mouse-over-popover" : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) =>
                                  handlePopoverOpen(event, item.CHEQUEREF)
                                }
                                onMouseLeave={handlePopoverClose}
                                sx={{ fontSize: matches ? 11 : 14 }}
                              >
                                {matches
                                  ? //   ? item.CHEQUEREF.substring(0, 30)
                                    //   : item.CHEQUEREF.substring(0, 60)
                                    item.COMPANY_NAME.substring(0, 30)
                                  : item.COMPANY_NAME.substring(0, 60)}
                              </Typography>
                            </TableCell> */}
                            {/* <StyledTableCell align="right">
                              {matches ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                              {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                            </StyledTableCell> */}

                            {/* <StyledTableCell align="right">
                              <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  //showPurchasePaymentModalHandler(event, 1, item);
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 1,
                                  }}
                                />
                              </Button>
                            </StyledTableCell> */}
                          </MyTableRow>

                          {open1[item.PAYOUT_ID] && (
                            <TableRow key={Math.random()}>
                            <MyTableCell colSpan={matches3?4:3}>
                              {"... " + "Inv: " + item.PURCHASEINVOICE_NUMBER.substring(0, 50)}
                            </MyTableCell>
                            <MyTableCell>£{" "}
                              {parseFloat(
                                item.TOTALPAID).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}</MyTableCell>
                            <MyTableCell></MyTableCell>
                            
                            
                            </TableRow>
                            // <DisplayExtraInfo open1={open1} item={item} />
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>

                  {emptyRows > 0 && (
                     <TableRow style={{ height: (matches3?20:matches2?30:40) * emptyRows }}>
                       <TableCell colSpan={matches3?5:6} />
                     </TableRow>
                     )}

                  {/* Footer--------------------------------------------------------------- */}
                  <TableFooter sx={{backgroundColor:theme.palette.common.ludisMain, color:theme.palette.common.ludisLight1}}>
                    <TableRow key={Math.random()}>
                      <TablePagination
                        //component="div"
                        
                        rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                        colSpan={matches3?5:6}
                        count={currentPaymentsOutInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native:true,
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        //extras------------------------------------------------
                        labelRowsPerPage={<span>{matches3?"":"rows"} </span>}
                        //labelDisplayedRows={({page})=>{return `Page: ${page}`}}
                        labelDisplayedRows={ matches3? ({page})=>{return `Page: ${page}`} :
                          (from=page) => `${from.from}-${from.to === -1 ? from.count : from.to} of ${from.count}`
                        }
                        //sx={{ color:theme.palette.common.ludisLight1 }}
                        sx={{ ".MuiTablePagination-toolbar":{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"baseline",
                              justifyContent:"center",
                              alignContent:"center",

                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              pt:1,
                              //margin:"auto"
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              width: "auto",
                              ml: matches3?"0":"0",
                              pl: matches3?"0":"1"


                            },
                            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":{
                              //display:"flex",
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              //margin:"auto"
                              //backgroundColor:"#000"
                            } ,
                            ".MuiTablePagination-selectIcon":{
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.8rem" : matches?"0.9rem": "1rem",
                              //backgroundColor:"#000"

                            },
                            ".MuiTablePagination-menuItem":{
                              color:theme.palette.common.ludisMain,
                              fontSize:"1rem",
                              
                              //backgroundColor:"#000"
                            }

                      }}
                      />
                    </TableRow>
                  </TableFooter>
                  {/* END Footer----------------------------------------------------------- */}


                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
    )
    
    

}

export default PaymentsOutInfo;