import { Fragment, useState, useRef, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
//import { paymentInActions } from "../../store/payment-slice";
import { comunicationInfoActions } from "../../../../store/comunication-slice";
import { variables } from "../../../../Variables";

import { format } from "date-fns";



// MUI IMPORTS---------------------------------------------------

import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";
import TextField from '@mui/material/TextField';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import Typography from "@mui/material/Typography";

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import theme from "../../../UI/Theme";
import { MyTextField, MyTextField2 } from "../../../UI/styledComponents/StyledComponents";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

// const maskMap = {
//   fr: "__/__/____",
//   en: "__/__/____",
//   de: "__.__.____",
// };

const CreateInvoiceStep_1 = (props) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  //console.log("here is the selected order...",selectedOrder)
  

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());

  const [enteredEmailHeadersInput, setEnteredEmailHeadersInput] = useState(null);
  const [enteredEmailHeadersInputName, setEnteredEmailHeadersInputName] = useState(null);
  const [enteredMessageInput, setEnteredMessageInput] = useState(null);
  const [enteredMessageInputName, setEnteredMessageInputName] = useState(null);

  //default Email for Create Email option------------------------------------------------ 
  let defaultEmailMessage =
  "\n" + 
  "Dear, " + selectedOrder[0].MAIN_CONTACT + "\n" + "\n" + 
  "Thank you for your time today. "+ "\n" + "\n" + 
  "Please see your invoice attached with payment details included. " + "\n" +  "\n" +
  "Kind Regards"

  let defaultEmailSubject= selectedOrder[0].CLUB_CODE+" - "+selectedOrder[0].CONFIRMATION_REFERENCE+" - "+selectedOrder[0].COMPANY_NAME
  
  let defaultEmail= selectedOrder[0].ClientEmail

 
  //END default Email for Create Email option------------------------------------------------ 

  //default Invoice Info -------------------------------------------------------------------- 
  let defaultInvoiceInfo =
  "\n" + 
  "Bill To, " + "\n" + "\n" + 
  "" + selectedOrder[0].MAIN_CONTACT + "\n" +
  "" + selectedOrder[0].COMPANY_NAME + "\n" +
  "" + selectedOrder[0].EMAIL + "\n" +
  "" + selectedOrder[0].ADDRESS1 + "\n" +
  "" + selectedOrder[0].ADDRESS2 + "\n" +
  "" + selectedOrder[0].TOWN + "\n" +
  "" + selectedOrder[0].POST_CODE  + "\n" + "\n" +

  
  "DATE:  " + selectedOrder[0].DATE + "\n" +  "\n" +


  "ITEM. "+ "\n" + "\n" + 
  "Publishing Services / Products as per Order: " + selectedOrder[0].CLUB_CODE+" - "+selectedOrder[0].CONFIRMATION_REFERENCE  + "\n" +  "\n" +
  "Net: £" + selectedOrder[0].NET_AMOUNT + "\n" +  "\n" +
  "V.A.T: £" + selectedOrder[0].VAT_AMOUNT + "\n" +  "\n" +
  "Total: £" + selectedOrder[0].TOTAL_AMOUNT + "\n" +  "\n" +

  "Kind Regards"

 
  //END default Invoice Info ---------------------------------------------------------------- 


  //const { paymentTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = "de";

 


  //--------------------------------------------------------datemanager---------
//   const handleDatesChangesFromTo = (date) => {
//     const dateIsValid = date !== null && date !== "Invalid Date";

//     //console.log("choosen date from Add Signed P.plan component....", date);

//     setSelectedPaymentDate(date);

//     if (dateIsValid) {
//       //Add the Selected Order to the Payments In information
//       dispatch(
//         comunicationInfoActions.updateComunicationCreateEmail({
//           paymentPlanSignedDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
//         })
//       );

//     } else {
//       console.log("error, date not usable only 2 options available");
//       return;
//     }
//   };
  //-------------------------------------------------------END datemanager------


    const handleInputChangeEmailHeaders = (value,name) => {

    setEnteredEmailHeadersInput(value);
    setEnteredEmailHeadersInputName(name);

    
    };

  const handleInputChangeMessage = (value,name) => {

    setEnteredMessageInput(value);
    setEnteredMessageInputName(name);

  };


  // Handle Download PDF invoice----------------------------------------------------------------------------
  const handleDownload = async(value,name) => {

    // Create a payload with the form data
    const invoiceData = {
        name: selectedOrder[0].MAIN_CONTACT,
        company: selectedOrder[0].COMPANY_NAME,
        email: selectedOrder[0].EMAIL,
        confo:selectedOrder[0].CLUB_CODE+"-"+selectedOrder[0].CONFIRMATION_REFERENCE,
        address1:selectedOrder[0].ADDRESS1 ,
        address2:selectedOrder[0].ADDRESS2 ,
        town:selectedOrder[0].TOWN,
        post:selectedOrder[0].POST_CODE,
        project:selectedOrder[0].CLUB_NAME+" "+selectedOrder[0].YEAR,
        net:selectedOrder[0].NET_AMOUNT,
        vat:selectedOrder[0].VAT_AMOUNT,
        total:selectedOrder[0].TOTAL_AMOUNT,
        invoiceDate:new Date(selectedOrder[0].DATE).toLocaleString("default", {
          day: "numeric",
          month: "short",
          year:"numeric",
        }),
        description:"Inv.content",
        bankDetails_1:"LUDIS MARKETING CONSULTING LTD",
        bankDetails_2:"SortCode: 04-29-09 -- Acc: 49153552",
        companyDetails:"Company No. 14918692 Reg. Office: 38 Church Street,  3rd Floor,  Seaford,  East Sussex,  United Kingdom,  BN25 1LD  VAT No. 443 3170 21"

    };

    // const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //       withCredentials: true,
    //     },
    //   };


    try {

        const response = await axios.post(variables.API_URL + "comunication/createpdfinvoice", invoiceData,
            {
                responseType: 'blob',  // Important: Ensures the response is handled as a binary file
            }
        )

        // const response = await axios.post('http://localhost:3001/generate-pdf', payload, {
        //     responseType: 'blob',  // Important: Ensures the response is handled as a binary file
        // });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "document.pdf");
        document.body.appendChild(link);
        link.click();

        console.log("downloading...")

    } catch (error) {console.error('Error generating PDF.. this is a Front end message:', error);}

    

  };
  // END Handle Download PDF invoice-------------------------------------------------------------------------





    //Debouncer..............

    useEffect(() => {
        const identifier = setTimeout(() => {

          dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({  
              [enteredEmailHeadersInputName]: enteredEmailHeadersInput,
              [enteredMessageInputName]: enteredMessageInput,
          })
        );

      
          // if(enteredMessageInput){
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //           [enteredMessageInputName]: enteredMessageInput, // to upgraded in a later stage...
          //           // comunicationMessage: enteredMessageInput,
          //           // comunicationEmail : enteredEmailInput,
          //           // comunicationEmailCC: "",
          //           // comunicationEmailSubject: "",
          //     })
          //   );  
          // }else{
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({  
          //       comunicationMessage: defaultEmailMessage,
          //       //comunicationEmail : enteredEmailInput,
          //     })
          //   );
            
          // }
            
        }, 500);
      
        return () => {
          // console.log("CleanUp");
          clearTimeout(identifier);
        };
      }, [enteredEmailHeadersInput, enteredEmailHeadersInputName, enteredMessageInput, enteredMessageInputName]);
    
    //Debouncer End...............................................................

    //Setting initial variables i.e email address and generic messsage..............
    useEffect(() => {

        dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({
                selectedOrder: selectedOrder,
            })
          );

          dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({
            comunicationMessage: defaultEmailMessage,
            comunicationEmail : defaultEmail,
            comunicationEmailCC : "",
            comunicationEmailSubject : defaultEmailSubject,
            comunicationEmailDate: new Date().toISOString(),
          })
        );   

          // if(enteredMessageInput){
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //           comunicationMessage: enteredMessageInput,
          //           //comunicationEmail : enteredEmailInput,
          //     })
          //   );  
          // }else{
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //       comunicationMessage: defaultEmailMessage,
          //       comunicationEmail : defaultEmail,
          //       comunicationEmailCC : "",
          //       comunicationEmailSubject : defaultEmailSubject,

          //     })
          //   ); 
          // }
    
      }, []);
    
      //Inicial Variables set End..................................................      

  



  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              "& .MuiTextField-root": {
                m: 1,
                width: "20vw",
                [theme.breakpoints.down("lg")]: {
                  width: "42vw",
                },
                [theme.breakpoints.down("md")]: {
                  width: "50vw",
                },
              },
            }}
          >
            {/* Email Address */}
            <MyTextField2
              name="comunicationEmail"
              id="outlined-search"
              label="email"
              type="search"
              defaultValue={defaultEmail}
              // sx={
              //   matches
              //     ? { width: "50vw", margin: "0.2em" }
              //     : { width: "40vw", margin: "auto" }
              // }
              onChange={(event) =>
                handleInputChangeEmailHeaders(
                  event.target.value,
                  event.target.name
                )
              }
            />

            <MyTextField2
              name="comunicationEmailCC"
              id="outlined-search"
              label="CCemail"
              type="search"
              // sx={
              //   matches
              //     ? { width: "50vw", margin: "0.2em" }
              //     : { width: "40vw", margin: "auto" }
              // }
              onChange={(event) =>
                handleInputChangeEmailHeaders(
                  event.target.value,
                  event.target.name
                )
              }
            />

            <MyTextField2
              name="comunicationEmailSubject"
              id="outlined-search"
              label="Subject"
              type="search"
              defaultValue={defaultEmailSubject}
              // sx={
              //   matches
              //     ? { width: "50vw", margin: "0.2em" }
              //     : { width: "40vw", margin: "auto" }
              // }
              onChange={(event) =>
                handleInputChangeEmailHeaders(
                  event.target.value,
                  event.target.name
                )
              }
            />
          </Box>

          <Divider />

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "24vw",
                [theme.breakpoints.down("lg")]: {
                  width: "48vw",
                },
                [theme.breakpoints.down("md")]: {
                  width: "50vw",
                },
              },
            }}
            noValidate
            autoComplete="off"
          >
            <MyTextField2
              id="filled-multiline-static"
              name="comunicationMessage"
              label="Type your email here...."
              multiline
              rows={10}
              defaultValue={defaultEmailMessage}
              variant="filled"
              onChange={(event) =>
                handleInputChangeMessage(event.target.value, event.target.name)
              }
            />

            <MyTextField2
              id="filled-multiline-static"
              name="comunicationMessage"
              label="Invoice Details here...."
              multiline
              rows={10}
              defaultValue={defaultInvoiceInfo}
              variant="filled"
              onChange={(event) =>
                handleInputChangeMessage(event.target.value, event.target.name)
              }
            />
          </Box>
          <Button
            onClick={(event) => {
              event.preventDefault();
              //showComunicationModalHandler(event, 1);
              handleDownload()
            }}
            sx={{ color: "#7A1125", textTransform: "capitalize" }}
          >
            <SimCardDownloadIcon
              sx={{
                fontSize: 20,
                //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                //color: !invoiceRecorded ? "#ccc" : "#4E1721",
                marginRight: 2,
                marginTop: 2,
              }}
            />
            <Typography
              align="center"
              //variant="body2"
              //color="text.secondary"
              sx={{ marginTop: 2, fontSize: 12 }}
            >
              Download Invoice 

            </Typography>
          </Button>
        </Box>
      </Fragment>
    </LocalizationProvider>
  );
};

export default CreateInvoiceStep_1;
