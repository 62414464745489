import { Fragment } from "react";

//----------REDUX IMPORTS --------------------------------
import { useSelector } from "react-redux";


import theme from "./UI/Theme";

//MUI IMPORTS-------------------------
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LogoutIcon from '@mui/icons-material/Logout';
import ReplyIcon from '@mui/icons-material/Reply';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const Notification = (props) => {
  const { notificationsList, matches, matches2, matches3 } = props;

  const userInfo = useSelector((state) => state.user);

  //console.log("this is my userinfo...",userInfo)

  //console.log("Notifications.......from the notification component", notificationsList)
  //const notificationsListReversed = notificationsList.slice().reverse();
  // const notificationsListSorted = props.notificationsList.slice(0).sort((a, b) => {
  //   const dateTimeA = new Date(`${a.notificationDate} ${a.notificationTime}`);
  //   const dateTimeB = new Date(`${b.notificationDate} ${b.notificationTime}`);
  //   return dateTimeB - dateTimeA ;
  // });

   return notificationsList.map((notification) =>
     //return notificationsListSorted.map((notification) => (

     //show Operatinal information only for management... to be upgraded...........
     notification.type.notificationType === 6 && userInfo.permission < 5 ? (
       "."
     ) : (
       <Fragment>
         <ListItem
           alignItems="flex-start"
           sx={{
             display: "flex",
             flexDirection: matches3 ? "column" : "row",
             alignItems: matches3 ? "center" : "center",
             //minWidth:"20vw",
             width: "auto",
             //width:"20vw",
             minWidth: matches3
               ? "65vw"
               : matches2
               ? "40vw"
               : matches
               ? "30vw"
               : "18vw",
             margin: "auto",
             //marginLeft: matches3 ? 0 : matches2 ? 20 : matches ? 20 :30,
             //backgroundColor:"#ccc",
           }}
         >
           <ListItemAvatar>
             {/* <Avatar alt="Remy Sharp" src={AvatarTestPic} /> */}
             {/* <Avatar alt="user pic" src='https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/WhatsApp_Image_2021-01-10_at_20.00.07_Ub7bsKGLXl5.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1631192701589&tr=w-1104%2Ch-736%2Cfo-auto' /> */}
             <Avatar
               alt="user pic"
               src={notification.senderPic}
               sx={{ margin: matches3 ? "auto" : "" }}
             />
           </ListItemAvatar>
           <ListItemText
             primary={
               <Typography
                 component="span"
                 variant="body2"
                 sx={{
                   fontSize: matches3 ? "0.8rem" : "1rem",
                   display: "flex",
                   flexDirection: "row",
                   alignItems: matches3 ? "center" : "flex-start",
                   justifyContent: matches3 ? "center" : "flex-start",
                 }}
                 // color="text.secondary"
               >
                 {notification.type.notificationType === 1 && (
                   <LogoutIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.okGreen,
                       mr: 1,
                     }}
                   />
                 )}
                 {(notification.type.notificationType === 4) && (
                   <ReplyIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.arcOrange,
                       mr: 1,
                     }}
                   />
                 )}
                 {(notification.type.notificationType === 8) && (
                   <ReceiptIcon
                   key={`${Math.random()}`}
                   sx={{
                     fontSize: 20,
                     color: theme.palette.common.arcOrange,
                     mr: 1,
                   }}
                 />
                 )}
                 
                 {(notification.type.notificationType === 5 ||
                   notification.type.notificationType === 6) && (
                   <ReplyIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.ludisMain2,
                       mr: 1,
                     }}
                   />
                 )}
                 {notification.type.notificationType === 3 && (
                   <ReceiptIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.arcBlue,
                       mr: 1,
                     }}
                   />
                 )}
                 {notification.type.notificationType === 2 && (
                   <DriveFileRenameOutlineIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.arcBlue,
                       mr: 1,
                     }}
                   />
                 )}
                 {notification.type.notificationType === 7 && (
                   <ForwardToInboxIcon
                     key={`${Math.random()}`}
                     sx={{
                       fontSize: 20,
                       color: theme.palette.common.arcBlue,
                       mr: 1,
                     }}
                   />
                 )}

                 {/* {`New ${notification.name} Submitted !`} */}
                 {/* {`New ${notification.name} `} */}
                 {`${notification.name} `}
               </Typography>
             }
             secondary={
               <Fragment>
                 <Box
                   sx={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: matches3 ? "center" : "flex-start",
                   }}
                 >
                   {/* {notification.notificationDetails && ( */}
                   {/* checking if the notification is a new Invoice Booked or new P.Plan Signed ------------------------------------------------------- */}
                   {(notification.type.notificationType === 3 ||
                     notification.type.notificationType === 2) && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Ref:  ${notification.notificationDetails.ref}`} */}
                         {`Ref:  ${notification.notificationDetails.ref}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Client:  ${notification.notificationDetails.company}`} */}
                         {`Client:  
                      ${
                        matches3
                          ? notification.notificationDetails.company.substring(
                              0,
                              15
                            )
                          : notification.notificationDetails.company.substring(
                              0,
                              22
                            )
                      }`}
                       </Typography>
                     </>
                   )}

                   {/* checking if the notification is a new Supplier Payment Out ------------------------------------------------------- */}

                   {/* {notification.notificationDetails_Purchase?.isSupplier && ( */}
                   {notification.type.notificationType === 4 && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Inv.:  ${notification.notificationDetails_Purchase.invoice}`} */}
                         {`Inv.:  ${notification.notificationDetails?.invoice.substring(
                           0,
                           22
                         )}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Supplier:  ${notification.notificationDetails_Purchase.supplier}`} */}
                         {`Supplier:  
                      ${
                        matches3
                          ? notification.notificationDetails?.supplier.substring(
                              0,
                              15
                            )
                          : notification.notificationDetails?.supplier.substring(
                              0,
                              22
                            )
                      }`}
                       </Typography>

                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Paid:  ${notification.notificationDetails_Purchase.purchasePaid}`} */}
                         {"Amount Paid: £ " +
                           parseFloat(
                             // notification.notificationDetails_Purchase.purchasePaid
                             notification.notificationDetails?.purchasePaid
                           ).toLocaleString("en-US", {
                             minimumFractionDigits: 2,
                           })}
                       </Typography>
                     </>
                   )}

                   {/* checking if the notification is a new Purchase Invoice Booked  ------------------------------------------------------- */}
                   {(notification.type.notificationType === 8 ) && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Ref:  ${notification.notificationDetails.ref}`} */}
                         {`Ref:  ${notification.notificationDetails.purchaseInvoice}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Paid:  ${notification.notificationDetails_Purchase.purchasePaid}`} */}
                         {"Amount: £ " +
                           parseFloat(
                             // notification.notificationDetails_Purchase.purchasePaid
                             notification.notificationDetails?.purchaseTotal
                           ).toLocaleString("en-US", {
                             minimumFractionDigits: 2,
                           })}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Client:  ${notification.notificationDetails.company}`} */}
                         {`Supplier: 
                      ${
                        matches3
                          ? notification.notificationDetails.supplier.substring(
                              0,
                              15
                            )
                          : notification.notificationDetails.supplier.substring(
                              0,
                              22
                            )
                      }
                      `
                      }
                       </Typography>
                     </>
                   )}



                   {/* {notification.notificationDetails_Purchase?.isWages && ( */}
                   {/* checking if the notification is a new Wages Payment Out ------------------------------------------------------- */}
                   {(notification.type.notificationType === 5 ||
                     notification.type.notificationType === 6) && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`W.Inv.:  ${notification.notificationDetails_Purchase.wagesInvoice}`} */}
                         {`W.Inv.:  ${notification.notificationDetails?.wagesInvoice}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Consultant:  ${notification.notificationDetails_Purchase.consultant}`} */}
                         {`Consultant:  
                      ${
                        notification.notificationDetails?.consultant
                          .charAt(0)
                          .toUpperCase() +
                        notification.notificationDetails?.consultant
                          .toLowerCase()
                          .slice(1)
                      }`}
                       </Typography>

                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Paid:  ${notification.notificationDetails_Purchase.purchasePaid}`} */}
                         {
                           "Amount Paid: " +
                             //notification.notificationDetails_Purchase.wagesPaid
                             notification.notificationDetails?.wagesPaid
                           // parseFloat(
                           //   notification.notificationDetails_Purchase.wagesPaid
                           // ).toLocaleString("en-US", { minimumFractionDigits: 2 })
                         }
                       </Typography>
                     </>
                   )}

                   {/* {notification.notificationDetails_Payment && ( */}
                   {/* checking if the notification is a new Payment In ------------------------------------------------------- */}
                   {notification.type.notificationType === 1 && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Order.:  ${notification.notificationDetails_Payment.confo}`} */}
                         {`Order.:  ${notification.notificationDetails.confo}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Client:  ${notification.notificationDetails_Payment.client}`} */}
                         {`Client:  ${notification.notificationDetails.client.substring(
                           0,
                           22
                         )}`}
                       </Typography>

                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Amount Paid:  ${notification.notificationDetails_Payment.amountPaid}`} */}
                         {"Amount Paid: £ " +
                           parseFloat(
                             //notification.notificationDetails_Payment.amountPaid
                             notification.notificationDetails.amountPaid
                           ).toLocaleString("en-US", {
                             minimumFractionDigits: 2,
                           })}
                       </Typography>
                     </>
                   )}

                   {/* checking if the notification is a new Comunication Email ------------------------------------------------------- */}
                   {notification.type.notificationType === 7 && (
                     <>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         // variant="body2"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Order.:  ${notification.notificationDetails_Payment.confo}`} */}
                         {`Order.:  ${notification.notificationDetails.confo}`}
                       </Typography>
                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Client:  ${notification.notificationDetails_Payment.client}`} */}
                         {`Client:  ${notification.notificationDetails.client.substring(
                           0,
                           22
                         )}`}
                       </Typography>

                       <Typography
                         sx={{ display: "inline" }}
                         component="span"
                         variant="body2"
                         color="text.secondary"
                       >
                         {/* {`Amount Paid:  ${notification.notificationDetails_Payment.amountPaid}`} */}
                         {`Type: ${notification.notificationDetails.type}`}
                       </Typography>
                     </>
                   )}

                   <Typography
                     sx={{
                       display: "inline",
                       fontSize: matches3 ? "0.6rem" : "0.8rem",
                     }}
                     component="span"
                     // variant="body2"
                     variant="body2"
                     color="text.secundary"
                   >
                     {/* {`By:  ${notification.senderName}`} */}
                     {`By:  ${
                       notification.senderName.userName
                         .charAt(0)
                         .toUpperCase() +
                       notification.senderName.userName.toLowerCase().slice(1)
                     }`}
                   </Typography>
                   <Typography
                     sx={{
                       display: "inline",
                       fontSize: matches3 ? "0.6rem" : "0.8rem",
                     }}
                     component="span"
                     // variant="body2"
                     variant="body2"
                     color="text.secundary"
                   >
                     {/* {`By:  ${notification.senderName}`} */}
                     {`  on:  ${notification.notificationDate}`}
                   </Typography>
                   {/* {`  on:  ${notification.notificationDate}`} */}
                   <Typography
                     sx={{
                       display: "inline",
                       fontSize: matches3 ? "0.6rem" : "0.8rem",
                     }}
                     component="span"
                     // variant="body2"
                     variant="body2"
                     color="text.secundary"
                   >
                     {/* {`By:  ${notification.senderName}`} */}
                     {`  at:  ${notification.notificationTime || "..."}`}
                   </Typography>
                   {/* {`  at:  ${notification.notificationTime || "..."}`} */}
                 </Box>
               </Fragment>
             }
           />
         </ListItem>
         <Divider sx={{ width: "25vw", marginBottom: 1 }} />
       </Fragment>
     )
   );
};

export default Notification;
