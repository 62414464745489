const customColors = {
    primary: '#795548',
    secondary: '#FFCA28',
    background: '#EFEBE9',
    background_white: '#fff',
    ludisMain: "#4E1721", //dark red ludis
    ludisLight: "#D7CCC8", // very light ludis
    ludisMain2: "#7A1125", //light red ludis
    
    text: {
      primary: '#3E2723',
      secondary: '#5D4037',
    }
  };
  
  const customStyles = {
    button: {
      backgroundColor: customColors.primary,
      color: 'white',
      '&:hover': {
        backgroundColor: '#5D4037',
      },
    },
    dialog: {
      '& .MuiDialogPaper': {
        //backgroundColor: customColors.background,
        backgroundColor: customColors.background_white,
      },
    },
    dialogTitle: {
      backgroundColor: customColors.ludisMain,
      color: 'white',
      fontSize: '1.2rem',
    },
    linearProgress: {
      height: 10,
      borderRadius: 5,
      backgroundColor: '#D7CCC8',
      '& .MuiLinearProgress-bar': {
        backgroundColor: customColors.ludisMain,
      },
    },
    stepIcon: {
      color: customColors.primary,
      '&.Mui-active': {
        color: customColors.secondary,
      },
      '&.Mui-completed': {
        color: customColors.primary,
      },
    },
    input: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#BCAAA4',
        },
        '&:hover fieldset': {
          borderColor: '#8D6E63',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5D4037',
        },
      },
      '& .MuiInputLabel-root': {
        color: '#5D4037',
      },
      '& .MuiInputBase-input': {
        color: '#3E2723',
      },
    },
    icon: {
    //   color: '#8D6E63',
      color: '#FFCA28',
      marginRight: '8px',
    },
  };
  
  export { customColors, customStyles };
  
  